/**
 * Convert to builder.io
 */

import type { DataFunctionArgs, MetaFunction } from "@remix-run/node";

import { getSocialMetas, host } from "~/utils";

/**
 * @cached
 * xxxx
 */

export { links } from "~/links";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	if (!data?.content) return getSocialMetas({ url: `${host()}/` });
	const { title, description } = data?.content.data ?? {};

	return getSocialMetas({ url: `${host()}/`, title, description });
};

export default function Index() {
	return (
		<div className="flex flex-col min-h-screen bg-slate-50">
			<main className="flex-grow flex items-center justify-center py-16 px-4">
				<div className="max-w-2xl text-center">
					<h1 className="text-3xl font-bold text-gray-900 mb-6">
						Farewell and Thank You
					</h1>

					<div className="space-y-4 text-gray-600">
						<p className="text-lg">
							We've made the difficult decision to shut down Snipbot.
						</p>

						<p>
							We want to express our sincere gratitude to all our users who
							supported us throughout this journey. Thank you for being part of
							our story.
						</p>
					</div>

					<div className="mt-8 text-sm text-gray-500">
						Last updated: {new Date().toLocaleDateString()}
					</div>
				</div>
			</main>
		</div>
	);
}
